import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/NDyHywLn-lc">
    <SEO title="Living out the Gospel - Romans: Practice" />
  </Layout>
)

export default SermonPost
